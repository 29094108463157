import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { log } from 'console';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LandingService {
  idAssoc: number;

  constructor(private http: HttpClient,) {

  }

  getProyecto(params: any) {
    let domain = {
      domain: params
    }
    let http_params = new HttpParams({
      fromObject: domain
    });

    // console.log(http_params);
    return new Promise(async (resolve, reject) => {
      const res$ = await this.http.get(`https://back.4sales.mx/api/get-project/torredacita`, { params: http_params }).pipe(map(response => response));
      let res: any = await res$.toPromise();
      // let res: any = await lastValueFrom(res$);
      // console.log("holis",res);
      // if (res.res) {
      //   console.log(res)
      //   // this.page_info = res.data;
      //   // this.setInfoLocalStorage(res.data);
      // }

      resolve(res.data);
    });
  }

  getServer(url: string, domain: string) {

    //Sustituir esta linea cuando ya este en produccion
    // domain = 'ampisanluis.com';
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL_LANDING + url + '/' + domain).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );

    });
  }

  getServer2(url: string) {

    //Sustituir esta linea cuando ya este en produccion
    // domain = 'ampisanluis.com';
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL_LANDING + url).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );

    });
  }

  getServerWithPage(url: string, domain: string, page: number) {

    //Sustituir esta linea cuando ya este en produccion
    // domain = 'ampisanluis.com';
    return new Promise((resolve, reject) => {
      this.http.get(environment.API_URL_LANDING + url + '/' + domain + '/' + page).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );

    });
  }


  postServer(url: string, params: any) {
    return new Promise((resolve, reject) => {
      this.http.post(environment.API_URL_LANDING + url, params).subscribe(
        (res) => {
          resolve(res);
        }, (err) => {
          reject(err);
        }
      );
    });
  }



  // getServer2(url: string, page: number) {
  //   let header = new HttpHeaders({
  //     Authorization: 'Bearer ' + "1|L9c5HZvLJ96JmOMHnvZses3F5KSYMMjvLipVK4sN"
  //   });

  //   return new Promise((resolve, reject) => {
  //     this.http.get(environment.API_URL_LANDING + url + '/' + page/* , { headers: header, params: {'page':page } } */).subscribe(
  //       (res) => {
  //         resolve(res);
  //       }, (err) => {
  //         reject(err);
  //       }
  //     );
  //   });
  // }
}
